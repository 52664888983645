import React from 'react';
import styled from 'styled-components';

const Container = styled.footer`
  height: 5rem;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Footer = () => <Container></Container>;

Footer.propTypes = {};

export default Footer;
